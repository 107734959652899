<script setup lang="ts">
// Max height has to be in pixels
const props = defineProps<{
  value: string;
  maxHeight?: string;
}>();

const expanded = ref(false);
const contentRef = ref<HTMLElement | null>(null);

const showToggle = computed(() => {
  if (!props.maxHeight || !contentRef.value) return false;
  return contentRef.value.scrollHeight > parseInt(props.maxHeight);
});

const containerStyle = computed(() => ({
  maxHeight: expanded.value ? 'none' : props.maxHeight,
}));

onMounted(() => {
  // Trigger reactivity for showToggle
  setTimeout(() => {
    contentRef.value && contentRef.value.scrollHeight;
  }, 5);
});
</script>
<template>
  <div class="relative">
    <div
      ref="contentRef"
      :style="containerStyle"
      class="overflow-scroll transition-all duration-300 opacity-60"
    >
      <MDC :value="value" tag="article" class="prose-lg prose-p:my-0" />
    </div>
    <UButton
      v-if="showToggle"
      variant="link"
      color="gray"
      size="lg"
      class="mt-2"
      @click="expanded = !expanded"
    >
      {{ expanded ? 'Show Less' : 'Show More' }}
    </UButton>
  </div>
</template>
